<template>
  <div
    class="loader-wrapper"
    :class="{ 'fit-height': fitHeight }"
  >
    <span
      class="loader loader-quart"
      :class="`loader--${size}`"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: 'lg',
      validator: size => ['md', 'lg'].includes(size)
    },
    fitHeight: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/loader.scss";
</style>
