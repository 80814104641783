<template>
  <v-dialog
    v-if="modalValue"
    :value="modalValue"
    :transition="transition"
    :width="width"
    :persistent="persistent || loading"
    :disabled="disabled"
    :eager="eager"
    :fullscreen="fullscreen || maximized"
    :retain-focus="retainFocus"
    :content-class="contentClasses"
    @input="handleModalClose"
    @keydown.enter.prevent="handlePressEnter"
  >
    <div
      class="default-modal"
      :class="[{
        'loading': loading,
      }, overflowClass]"
    >
      <template v-if="$slots.loader">
        <transition name="fade">
          <div
            v-if="loading"
            class="default-modal__loader"
          >
            <slot name="loader" />
          </div>
        </transition>
      </template>
      <template v-else>
        <Overlay :show="loading" />
      </template>
      <v-form
        :ref="`${id}-form`"
        class="default-modal__form"
        lazy-validation
        @submit.prevent.stop="handlePressEnter"
      >
        <div class="default-modal__header">
          <div class="default-modal__header-title">
            {{ title }}
          </div>
          <div class="default-modal__header-actions">
            <icon
              v-if="maximizable"
              v-tooltip.bottom="getMaximizeTooltip"
              :data="getMaximizeIcon"
              class="default-modal__header-icon fill-off"
              @click="handleMaximize"
            />
            <icon
              v-tooltip.bottom="getTooltip($t('Web.Modals.DiscardAndClose'))"
              data="@icon/close.svg"
              class="default-modal__header-icon size-14"
              @click="handleModalClose"
            />
          </div>
        </div>
        <div
          class="default-modal__content"
          :class="{
            'hide-footer': hideFooter
          }"
        >
          <slot />
        </div>
        <div
          v-if="!hideFooter"
          class="default-modal__footer"
          :class="{ 'default-modal__footer--between': footerBetween }"
        >
          <slot name="footer" />
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import { modal } from '@/mixins/modal';
import Overlay from '@/components/Shared/Overlay';
import { mapGetters } from 'vuex';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DefaultModal',
  components: {
    Overlay
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      required: false,
      default: 500
    },
    transition: {
      type: String,
      required: false,
      default: 'scroll-y-transition'
    },
    persistent: Boolean,
    disabled: Boolean,
    fullscreen: Boolean,
    maximizable: Boolean,
    eager: Boolean,
    overflowVisible: Boolean,
    // use :retainFocus="false" when vuetify throw focusin error
    // https://github.com/vuetifyjs/vuetify/issues/6892
    retainFocus: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    },
    position: {
      type: String,
      required: false,
      default: 'top',
      validator: (val) => ['top', 'center'].includes(val)
    },
    closeCallback: {
      type: [Function, null],
      required: false,
      default: null
    },
    hideFooter: Boolean,
    loading: Boolean,
    footerBetween: Boolean
  },
  data() {
    return {
      eventHandlers: [
        {
          // pass data to current modal (need for reuse shared modals)
          name: 'pass-data',
          handler: ({ id, ...data }) => {
            if (id === this.id) {
              this.$emit('created', data);
            }
          }
        },
        {
          name: `show:${this.id}`,
          handler: () => this.$emit('show')
        },
        {
          name: `shown:${this.id}`,
          handler: () => this.$emit('shown')
        },
        {
          name: `hide:${this.id}`,
          handler: () => this.$emit('hide')
        },
        {
          name: `hidden:${this.id}`,
          handler: () => this.$emit('hidden')
        }
      ],
      maximized: false,
      getTooltip
    };
  },
  computed: {
    ...mapGetters({
      modalValueById: 'modals/modalValueById'
    }),
    modalValue() {
      return this.modalValueById(this.id);
    },
    overflowClass() {
      return this.overflowVisible ? 'overflow-visible' : '';
    },
    contentClasses() {
      return `${this.position} ${this.contentClass} ${this.overflowClass}`;
    },
    getMaximizeIcon() {
      return require(`@icons/${this.maximized ? 'fullscreen_close.svg' : 'fullscreen.svg'}`);
    },
    getMaximizeTooltip() {
      const content = this.maximized ? this.$t('Web.Modals.BtnExit') : this.$t('Web.Modals.Fullscreen');

      return this.getTooltip(content);
    }
  },
  created() {
    this.eventHandlers.forEach(item => {
      this.$root.$on(item.name, item.handler);
    });
  },
  methods: {
    handleModalClose() {
      if (this.closeCallback) {
        return this.closeCallback();
      }

      this.hideModal(this.id);
    },
    handlePressEnter() {
      this.$emit('on-enter');
    },
    handleMaximize() {
      this.maximized = !this.maximized;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/modals/default-modal';
</style>