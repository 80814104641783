<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader-overlay"
    >
      <template v-if="$slots.loader">
        <slot name="loader" />
      </template>
      <Loader v-else />
    </div>
  </transition>
</template>

<script>
import Loader from '@/components/Shared/Loader';

export default {
  name: 'Overlay',
  components: {
    Loader
  },
  props: {
    show: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/components/shared/overlay.scss";
</style>